import { animate } from "motion";
import { Match, onMount, Show, Switch } from "solid-js";
import { HubbleFullSvg } from "~/assets/assets";
import { toRupees } from "~/utils/number";
import { classNames, getHexColorBrightness } from "../../utils/etc";

type VoucherGenerationBrandCardProps = {
  plainLogoUrl: string;
  cardBackgroundColor: string;
  showAmount?: boolean;
  amount?: number;
  showHubbleLogo: boolean;
  borderClass?: string;
};

export function VoucherGenerationBrandCard(
  props: VoucherGenerationBrandCardProps
) {
  const textColor =
    getHexColorBrightness(props.cardBackgroundColor) > 170 ? "black" : "white";

  const gradientColor = textColor;

  const invertedTextColor = textColor === "black" ? "white" : "black";

  onMount(() => {
    const element = document.querySelector(".anim-card-bg-1")!;
    animate(
      element,
      {
        opacity: [0, 0.05, 0.1],
        rotate: [90, 60, 30],
        transform: [
          "translateX(-60px) rotate(30deg)",
          "translateX(-30px) rotate(30deg)",
          "translateX(0px) rotate(30deg)",
        ],
      },
      { duration: 0.3, easing: "ease-in-out" }
    );

    const element2 = document.querySelector(".anim-card-bg-2")!;
    animate(
      element2,
      {
        opacity: [0, 0.05, 0.1],
        rotate: [90, 60, 30],
        transform: [
          "translateX(20px) rotate(30deg)",
          "translateX(10px) rotate(30deg)",
          "translateX(0px) rotate(30deg)",
        ],
      },
      { duration: 0.6, easing: "ease-in-out" }
    );
  });

  return (
    <div class=" w-full ">
      <div
        class={classNames(
          "relative flex  flex-row   overflow-hidden ",
          props.borderClass ??
            "darkTheme:border-[#232323] rounded-3xl border border-neutral-200 dark:border-[#232323]"
        )}
        style={{
          "background-color": props.cardBackgroundColor,
          "aspect-ratio": 358 / 210,
          "box-shadow": "0px 2px 8px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <div
          style={{
            "background-image": `linear-gradient(to bottom ,  ${gradientColor} , transparent)`,
          }}
          class="anim-card-bg-1 absolute -right-[12%] -top-20 h-[190%] w-[62%] rotate-[30deg] opacity-10"
        />
        <div
          style={{
            "background-image": `linear-gradient(to bottom , transparent, ${gradientColor})`,
          }}
          class="anim-card-bg-2 absolute  -right-[12%] -top-24 h-[190%] w-[40%] rotate-[30deg] opacity-10"
        />
        <Switch>
          <Match when={props.showAmount}>
            <AmountComponents {...props} />
          </Match>
          <Match when={true}>
            <NoAmountComponents {...props} />
          </Match>
        </Switch>
      </div>
    </div>
  );
}

function AmountComponents(props: VoucherGenerationBrandCardProps) {
  const textColor =
    getHexColorBrightness(props.cardBackgroundColor) > 170 ? "black" : "white";

  return (
    <div>
      <div class="h-[60px] w-[160px]">
        <img
          src={props.plainLogoUrl}
          class="absolute bottom-[16px] left-[16px]  max-h-[60px] max-w-[160px] lg:bottom-[20px] lg:left-[20px] lg:h-10"
          alt="brand logo"
        />
      </div>
      <div class="absolute right-0 top-0">
        <div
          style={{
            transform: "rotateY(180deg)",
          }}
        >
          <Ribbon strokeColor={textColor} />
        </div>
      </div>
      <div
        style={{
          color: ` ${textColor}`,
        }}
        class="absolute left-[16px] top-[16px] flex flex-col lg:left-[20px] lg:top-[20px]"
      >
        <span class="text-f12Bold lg:text-mediumBold">Gift card worth</span>
        <span class="text-h3 lg:text-h1">{toRupees(props.amount!)}</span>
      </div>
      <Show when={props.showHubbleLogo}>
        <HubbleFullSvg
          class={classNames(
            "absolute bottom-[16px] right-[16px] h-[14px] w-28 opacity-50 lg:bottom-[20px] lg:right-[20px] lg:h-5  "
          )}
          fill={textColor}
        />
      </Show>
    </div>
  );
}

function NoAmountComponents(props: VoucherGenerationBrandCardProps) {
  const textColor =
    getHexColorBrightness(props.cardBackgroundColor) > 170 ? "black" : "white";

  return (
    <div>
      <img
        src={props.plainLogoUrl}
        class="absolute bottom-[20px] left-[20px] h-[34px] lg:bottom-[33px] lg:left-[33px] lg:h-[54px]"
      />
      <div class="absolute bottom-0 left-0 right-0 top-0">
        <RibbonLarge strokeColor={textColor} />
      </div>

      <HubbleFullSvg
        class={classNames(
          "absolute bottom-[20px] right-[20px] h-[20px] w-28 opacity-50 lg:bottom-[33px] lg:right-[33px] lg:h-7  "
        )}
        fill={textColor}
      />
    </div>
  );
}

function Ribbon(props: { strokeColor?: string }) {
  return (
    <img
      class="h-[148px] lg:h-[210px]"
      src={
        "https://assets.myhubble.money/web-assets/voucher-card-decoration.svg"
      }
      alt="Gift card ribbon"
    />
  );
}

function RibbonLarge(props: { strokeColor?: string }) {
  return (
    <img
      class="h-full"
      src={
        "https://assets.myhubble.money/web-assets/voucher-card-decoration.svg"
      }
      alt="Gift card ribbon for larger screens"
    />
  );
}
